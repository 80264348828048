import {Component, EventEmitter, OnInit} from '@angular/core';
import { Organisation, OrganisationService } from '../organisation.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import {PgTitleComponent} from '../../shared/components/title-bar/title-bar.component';
import { PgMessagesService, MessageLevel } from 'src/app/shared/services/pg-messages.service';
import { ActionType, DataBaseActionService } from 'src/app/shared/services/database-action.service';

@Component({
  selector: 'app-new-organisation',
  templateUrl: './new-organisation.component.html',
})
export class NewOrganisationComponent implements OnInit, PgTitleComponent {
  title = new EventEmitter<string>();

  newOrganisation: Organisation = null;
  parentOrganisations: Organisation[];
  edit: boolean;
  showMessage: boolean;
  message: string;
  success: boolean;
  objectKeys = Object.keys;
  availableTimeZones: string[];

  availableProperties = {
    isBuyer: 'خریدار',
    isSeller: 'فروشنده',
    isTransporter: 'حمل کننده',
    isUnloading: 'قابل تخلیه',
    isLoading: 'قابل بارگیری',
    isPort: 'پایانه دریایی',
    isShipper: 'حمل کننده دریایی',
    isConsignee: 'گیرنده دریایی',
    isShippingAgent: 'ایجنت دریایی',
    isNotifying: 'Notifying',
    isAnchorage: 'لنگرگاه دریایی',
    isSurvey: 'بازرس کیفیت',
    isBunkerSupplier: 'Bunker Supplier',
    isCharterer: 'Charterer',
    domestic : 'فعالیت درون کشوری',
    international: 'فعالیت بین المللی',
    correspondent: 'Correspondent',
    office: 'Office',
    airport: 'Airport',
    country: 'Country',
    owner: 'Ship Owner',
    commercial: 'Ship Commercial',
    technical: 'Ship Technical',
    allowEnteryFromUI:'Allow Entery From UI',
    vesselManager: 'Vessel Manager',
    vesselBuilder: 'Vessel Builder',
    vesselProtector: 'Vessel Protection and Indemnity'
  };

  propertyDic: {
    [key: string]: boolean;
  } = { };

  constructor(private _organisationsService: OrganisationService,
              private _activatedRoute: ActivatedRoute,
              private _pgMessagesService: PgMessagesService,
              private _actionService: DataBaseActionService) {
    Object.keys(this.availableProperties).forEach(key => this.propertyDic[key] = false);
  }

  ngOnInit() {
    this.title.emit('Organisation');
    this.availableTimeZones = moment.tz.names();
    this.getCurrentOrganisation();
  }

  getCurrentOrganisation() {
    this._activatedRoute.paramMap.subscribe(p => {
      this.edit = (p.get('mode') === 'edit') ? true : false;
      const organisationId = p.get('organisationId');
      this._organisationsService.get()
      .subscribe(result => {
        this.parentOrganisations = result;
        if (!this.edit) {
          if (organisationId === 'root') {
            this.initialOrganisation('');
          } else {
            this.initialOrganisation(organisationId);
          }
        }
        if (this.edit) {
          this.newOrganisation = result.find(o => o.name === organisationId);
          this.newOrganisation.internalName = this.newOrganisation.name;
          if (this.newOrganisation.properties) {
            this.propertyDic = this.newOrganisation.properties;
            for (const p in this.availableProperties) {
              if (!this.newOrganisation.properties[p]) {
                this.propertyDic[p] = false;
              } else {
                this.propertyDic[p] = true;
              }
            }
          }
        }
      });
    });
  }

  add() {
    if (!this.newOrganisation.internalName.startsWith(this.newOrganisation.parentOrganisation)) {
      this.newOrganisation.name =
        `${this.newOrganisation.parentOrganisation}.${this.newOrganisation.internalName}`;
    } else  {
      this.newOrganisation.name = this.newOrganisation.internalName;
    }

    this.newOrganisation.properties = this.propertyDic;
    if(this.newOrganisation.actions === undefined) {
      this.newOrganisation.actions = [];
    }

    if (!this.edit) {
      this.newOrganisation.actions.push(this._actionService.userAction(ActionType.Create));
      this._organisationsService.add(this.newOrganisation)
        .subscribe(() => {
          this._pgMessagesService.publishMessage({
            message:  `${this.newOrganisation.nameTranslations['en']} Imported`,
            topic: 'New Organisation',
            level: MessageLevel.Info
          });
        });
    } else if (this.edit) {
      this.newOrganisation.actions.push(this._actionService.userAction(ActionType.Update));
      this._organisationsService.update(this.newOrganisation).subscribe(() => {
        this._pgMessagesService.publishMessage({
          message: ` ${this.newOrganisation.nameTranslations['en']} Updated`,
          topic: 'Organisation',
          level: MessageLevel.Info
        });
      });
    }
  }

  formatter(a: string) {
    return a;
  }

  initialOrganisation(parentId: string) {
    this.newOrganisation = {
      name: '',
      internalName: '',
      address: '',
      manager: '',
      parentOrganisation: parentId,
      nameTranslations: {
        fa: ''
      },
      properties: {},
      location: {
        longitude: 0,
        latitude: 0
      },
      timezone: (this.parentOrganisations.find(o => o.name === parentId)) ?
        this.parentOrganisations.find(o => o.name === parentId).timezone : '',
      actions: [],
      emailDomain: ''
    };
  }

}
