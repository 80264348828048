<div class="row">
  <div class="col-md-6">
    <form class="form-horizontal" *ngIf="newOrganisation"
      #organisationForm="ngForm">
      <div class="card card-body">
        <div class="form-group row">
          <label for="name" class="col-form-label col-md-4 ">شناسه</label>
          <div class="col-md-8">
            <input type="text"
              id="name"
              name="name"
              class="form-control"
              required
              [disabled]="edit"
              [(ngModel)]="newOrganisation.internalName">
          </div>
        </div>
        <div class="form-group row">
          <label for="parentOrganisation"
            class="col-form-label col-md-4 ">مجموعه</label>
          <div class="col-md-8">
            <input type="text"
              id="parentOrganisation"
              name="parentOrganisation"
              class="form-control"
              [disabled]="true"
              [(ngModel)]="newOrganisation.parentOrganisation">
          </div>
        </div>
        <div class="form-group row">
          <label for="farsiName" class="col-form-label col-md-4 ">نام
            فارسی</label>
          <div class="col-md-8">
            <input type="text"
              id="farsiName"
              name="farsiName"
              class="form-control"
              required
              [(ngModel)]="newOrganisation.nameTranslations['fa']">
          </div>
        </div>
        <div class="form-group row">
          <label for="englishName" class="col-form-label col-md-4 ">نام
            انگلیسی</label>
          <div class="col-md-8">
            <input type="text"
              id="englishName"
              name="englishName"
              class="form-control"
              required
              [(ngModel)]="newOrganisation.nameTranslations['en']">
          </div>
        </div>
        <div class="form-group row">
          <label for="manager" class="col-form-label col-md-4 ">مدیر</label>
          <div class="col-md-8">
            <input type="text"
              id="manager"
              name="manager"
              class="form-control"
              required
              [(ngModel)]="newOrganisation.manager">
          </div>
        </div>
        <div class="form-group row">
          <label for="address" class="col-form-label col-md-4 ">آدرس</label>
          <div class="col-md-8">
            <input type="text"
              id="address"
              name="address"
              class="form-control"
              required
              [(ngModel)]="newOrganisation.address">
          </div>
        </div>
        <div class="form-group row">
          <label for="latitude"
            class="col-form-label col-md-4 ">latitude</label>
          <div class="col-md-3">
            <input type="number"
              id="latitude"
              name="latitude"
              class="form-control"
              required
              [(ngModel)]="newOrganisation.location.latitude">
          </div>
          <label for="longitude"
            class="col-form-label col-md-2 ">longitude</label>
          <div class="col-md-3">
            <input type="number"
              id="longitude"
              name="longitude"
              class="form-control"
              required
              [(ngModel)]="newOrganisation.location.longitude">
          </div>
        </div>
        <div class="form-group row">
          <label for="tz" class="col-form-label col-md-4 ">Timezone</label>
          <div class="col-md-8">
            <app-auto-complete
              [formatter]="formatter"
              [(ngModel)]="newOrganisation.timezone"
              [items]="availableTimeZones"
              name="tz"
              id="tz"
              required>
            </app-auto-complete>
          </div>
        </div>
        <div class="form-group row">
          <label for="emailDomain" class="col-form-label col-md-4 ">Email domain</label>
          <div class="col-md-8">
            <input type="text"
              id="emailDomain"
              name="emailDomain"
              class="form-control"
              [(ngModel)]="newOrganisation.emailDomain">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-4 offset-md-4"
            *ngFor="let f of objectKeys(availableProperties)">
            <input type="checkbox"
              id="{{f}}"
              name="{{f}}"
              [(ngModel)]="propertyDic[f]">&nbsp;&nbsp;{{availableProperties[f]}}
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <button type="submit"
            class="btn btn-primary pull-right"
            (click)="add()"
            [disabled]="!organisationForm.form.valid">Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
